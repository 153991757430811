import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';

import gsap from "gsap/all.js";
import {TweenLite, TimelineMax, Power2 } from "gsap/all";

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Object
const geometry = new RoundedBoxGeometry( 2.4, 2.4, 2.4, 7, 0.07 );


const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(5, 5),
    new THREE.MeshBasicMaterial({
        color: 0xffffff
    })
)

const loader=new THREE.TextureLoader()

var materials = [

   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/cub_3.jpg')}),
   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/cub_2.jpg')}),

   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/Logo_png_NK.png')}),
   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/cub_4.jpg')}),

   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/cub_start.png')}),
   new THREE.MeshStandardMaterial( { roughness: 0.2, metalness: 0.7, map: loader.load('textures/cub_1.jpg')}),
];
const mesh = new THREE.Mesh(geometry, materials)
// mesh.castShadow=true
// mesh.receiveShadow = true
scene.add(mesh)

var center = new THREE.Vector3();
mesh.geometry.computeBoundingBox();
mesh.geometry.boundingBox.getCenter(center);
mesh.geometry.center();
mesh.position.copy(center);


/**
 * Floor
 */
//  const floor = new THREE.Mesh(
//     new THREE.PlaneGeometry(100, 100),
//     new THREE.MeshPhongMaterial({
//         color: 0xffffff,
//         metalness: 0.3,
//         roughness: 0.4
//     })
// )
// floor.receiveShadow = true
// // floor.rotation.x = - Math.PI * 0.5
// floor.position.z = -8
// floor.position.y = -2
// scene.add(floor)

if(window.innerWidth<780){
    mesh.scale.set(0.7,0.7,0.7)
}

// mesh.position.set(0,0.2,0)

 /**
  * Lights
  */
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.4)
  scene.add(ambientLight)
  
  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2)
//   directionalLight.castShadow = true
//   directionalLight.shadow.mapSize.set(1024, 1024)
//   directionalLight.shadow.camera.far = 15
//   directionalLight.shadow.camera.left = - 7
//   directionalLight.shadow.camera.top = 7
//   directionalLight.shadow.camera.right = 7
//   directionalLight.shadow.camera.bottom = - 7
  directionalLight.position.set(2,2,5)
  scene.add(directionalLight)
 
const pointLight = new THREE.PointLight(0xffffff, 0.4)
scene.add(pointLight)
pointLight.position.set(-4,2,5)
// pointLight.castShadow=true
// pointLight.shadow.mapSize.width = 1024
// pointLight.shadow.mapSize.height = 1024

// pointLight.shadow.camera.near = 0.1
// pointLight.shadow.camera.far = 5

const spotLight = new THREE.SpotLight(0xffffff, 0.4, 10, Math.PI * 0.3)
spotLight.castShadow = true

spotLight.position.set(-7,-.5,7)
scene.add(spotLight)
// scene.add(spotLight.target)
// spotLight.shadow.mapSize.width = 1024
// spotLight.shadow.mapSize.height = 1024
// spotLight.shadow.camera.fov = 30
// spotLight.shadow.camera.near = 1
// spotLight.shadow.camera.far = 9

// Sizes
 const sizes = {
     width: window.innerWidth,
     height: window.innerHeight*0.7
 }

// Camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 2, 5.5)
scene.add(camera)

 /**
  * Renderer
  */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas, antialias: true, alpha: true
})
// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.shadowMap.enabled = true

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight*0.7

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

 // Controls
 const controls = new OrbitControls(camera, canvas)
 controls.enableDamping = true
 controls.enableZoom = false

 
const tick = () =>
{
   

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    mesh.rotation.y-= 0.005

      
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

const environment = new RoomEnvironment();
const pmremGenerator = new THREE.PMREMGenerator( renderer );

scene.environment = pmremGenerator.fromScene( environment ).texture;


